import { CrossIcon, WindowingActivatedIcon } from 'components/ui/icons';
import UISelectionMenu from 'components/ui/menus/selection/SelectionMenu';
import { useWindowing } from 'context/Windowing';
import React from 'react';
import { activateDefaultTooling } from 'utils/cornerstone/tooling';
import { updateVolumeWindow } from 'utils/utilities';

import DefaultMenuCanvas from './DefaultMenuCanvas';

type DefaultMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

type WindowOption = {
  title: string;
  windowWidth: number;
  windowCenter: number;
};

const WindowingMenu: React.FC<DefaultMenuProps> = ({ setTopBar }) => {
  const { windowingValues, setWindowingValues } = useWindowing();

  const handleWindowingChange = (windowWidth: number, windowCenter: number) => {
    setWindowingValues({ windowWidth, windowCenter });
    updateVolumeWindow(windowWidth, windowCenter);
    activateDefaultTooling();
    setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />);
  };

  const windowOptions: WindowOption[] = [
    { title: 'Stroke', windowWidth: 40, windowCenter: 40 },
    { title: 'Brain', windowWidth: 80, windowCenter: 40 },
    { title: 'Contrast', windowWidth: 500, windowCenter: 180 },
    { title: 'Bone', windowWidth: 1000, windowCenter: 400 },
  ];

  const activeItem = windowOptions.find(
    (windowOption) => windowOption.windowWidth === windowingValues.windowWidth
      && windowOption.windowCenter === windowingValues.windowCenter,
  )?.title;

  const menuContent = windowOptions.map((windowOption) => ({
    title: windowOption.title,
    onClick: () => handleWindowingChange(windowOption.windowWidth, windowOption.windowCenter),
  }));

  return (
    <UISelectionMenu
      menuContent={menuContent}
      activeIcon={<WindowingActivatedIcon />}
      exitIcon={{
        icon: <CrossIcon />,
        onClick: () => {
          activateDefaultTooling();
          setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />);
        },
      }}
      id='windowing'
      menuItemClassName='windowing-menu-item'
      activeItem={activeItem}
    />
  );
};

export default WindowingMenu;
