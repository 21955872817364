import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    error: {
      main: '#FF786C',
    },
    background: {
      default: '#1C1C1C',
    },
    text: {
      primary: '#fff',
      secondary: '#A6A6A6',
    },
    warning: {
      main: '#B83F3F',
    },
  },

  typography: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
  },
});

export default theme;
