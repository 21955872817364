import ResultsAccordion from 'components/results/accordion/ResultAccordion';
import ResultsTable from 'components/results/table/ResultTable';
import { ExclamationBlueIcon, ExclamationRedIcon } from 'components/ui/icons';
import React from 'react';
import { ProductResult } from 'types';

export default function Results({ productResult }: { productResult: ProductResult }) {
  const { summary, ...results } = productResult;

  // Define the order we want to display the results
  const order = ['ncct_lvo', 'ncct_ischemia_volume', 'ncct_ich', 'ncct_ich_volume', 'cta_lvo'];

  const tableData = Object.entries(results)
    .sort(([keyA], [keyB]) => order.indexOf(keyA) - order.indexOf(keyB))
    .map(([key, value]) => ({
      label: key.replace(/_/g, ' ').toUpperCase(),
      value: value.agg_result,
    }));

  const isWarning = summary.includes('Suspected');

  return (
    <div>
      <ResultsAccordion
        className={`results-accordion-header${isWarning ? '-warning' : ''}`}
        icon={isWarning ? ExclamationRedIcon : ExclamationBlueIcon}
        header={{ value: summary }}
        detailedComponent={<ResultsTable data={tableData} />}
      />
    </div>
  );
}
