import { useState, useEffect } from 'react';
import { authentication } from '@microsoft/teams-js';
import getCaseData from '../utils/requests';
import mockup from '../mockups/example_diag_response.json';

export default function useCaseData() {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<{ status: number } | null>(null);

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const diagnosticId = params.get('diagnostic_id');
    const env = params.get('env');

    if (diagnosticId == null || env == null) {
      setError({ status: 400 });
      return;
    }

    const handleDataReq = async (token: string) => {
      try {
        const res = await getCaseData(token, diagnosticId, env);
        if (res.ok) {
          const result = await res.json();
          setData(result);
        } else {
          setError({ status: res.status });
        }
      } catch (err) {
        console.error(err);
        setError({ status: 500 });
      }
    };

    const fetchCaseData = async () => {
      try {
        authentication.getAuthToken({
          successCallback: async (token) => {
            await handleDataReq(token);
          },
          failureCallback: (MsError) => {
            console.error(MsError);
            setError({ status: 401 });
          },
        });
      } catch (err) {
        console.error(err);
        setError({ status: 500 });
      }
    };

    const checkDevMode = async () => {
      const isInDev = process.env.REACT_APP_TEST_MODE === 'true';
      if (!data && isInDev) {
        const devToken = process.env.REACT_APP_MICROSOFT_DEV_TOKEN;
        if (devToken) {
          await handleDataReq(devToken);
        } else {
          setData(mockup);
        }
      }
    };

    fetchCaseData();
    checkDevMode();
  }, []);

  return { data, error };
}
