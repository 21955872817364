import UIDropdown from 'components/ui/dropdown/Dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { formattedImageData, VolumeIds } from 'types';
import { loadNewVolume } from 'utils/cornerstone/setup';
import { applyDefaultColorMap, getDefaultLesion } from 'utils/heatmaps/heatmaps';
import { getViewport } from 'utils/utilities';

export const handleDropdownItemClick = async (imageMetaData: VolumeIds, setActiveLesion) => {
  await loadNewVolume(imageMetaData);

  const defaultLesion = getDefaultLesion();
  setActiveLesion(defaultLesion);
  if (defaultLesion) {
    applyDefaultColorMap();
  }
  const viewport = getViewport();
  if (viewport) {
    viewport.render();
  }
};

type ImageDropdownProps = {
  formattedImages: formattedImageData[];
};

const ImageDropdown = ({ formattedImages }: ImageDropdownProps) => {
  const indexRef = useRef(formattedImages.findIndex((img) => img.selectedImage));
  const [activeIndex, setActiveIndex] = useState(indexRef.current);
  useEffect(() => {
    indexRef.current = activeIndex;
  }, [activeIndex]);

  return (
    <UIDropdown items={formattedImages} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
  );
};

export default ImageDropdown;
