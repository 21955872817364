import Spinner from 'components/ui/spinner/Spinner';
import useToolingEffects from 'hooks/useToolingEffects';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { setup } from 'utils/cornerstone/setup';
import './Canvas.css';
import { SliderValue, VolumeIds } from 'types';

type CanvasProps = {
  initialVolumeIds: VolumeIds;
  setSliderValue: Dispatch<SetStateAction<SliderValue>>;
};
export default function Canvas({ initialVolumeIds, setSliderValue }: CanvasProps) {
  const elementRef = useRef<HTMLDivElement>(null);

  useToolingEffects(elementRef, setSliderValue);
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();
    /*
      This prevent you from zooming using your native browser/device
      functionality and allows for cornerstone zoom to be activated instead
    */
    elementRef.current?.addEventListener('touchmove', preventDefault);
    elementRef.current?.addEventListener('contextmenu', preventDefault);

    setup(elementRef, { main: initialVolumeIds.image, lesions: initialVolumeIds.lesions });
    return () => {
      elementRef.current?.removeEventListener('touchmove', preventDefault);
      elementRef.current?.removeEventListener('contextmenu', preventDefault);
    };
  }, []);

  return (
    <div className='nifti-image' ref={elementRef}>
      <Spinner />
    </div>
  );
}
