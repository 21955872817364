import useLoading from 'hooks/useLoading';
import React from 'react';
import './Spinner.css';

const Spinner: React.FC = () => {
  const { loadingProgress, isLoading } = useLoading();

  if (!isLoading) {
    return null;
  }

  return (
    <div className='spinner-container'>
      <div className='spinner'></div>
      <div className='loading-progress'>{loadingProgress}%</div>
    </div>
  );
};

export default Spinner;
