import React from 'react';
import { SliderValue } from 'types';
import { handleJumpToSlice, IncrementSliceByDelta } from '../../../utils/utilities';
import { ArrowIcon } from '../icons';
import './Slider.css';

/**
 * Calculates the new slider value based on touch position.
 *
 * @param touchPosition - The X-coordinate of the touch event.
 * @param value - The current object containing the currentSlice and maxSlice.
 * @returns The new slider value.
 */
function calculateNewSliderValue(touchPosition: number, value: SliderValue): number {
  const sliderElement = document.getElementById('volume-slider');
  const { left, width } = sliderElement.getBoundingClientRect();
  const positionRelativeToSlider = touchPosition - left;

  const touchIndex = positionRelativeToSlider / width;
  return Math.round(touchIndex * (value.maxSlice - 1));
}

const UISlider: React.FC<{
  setSliderValue: React.Dispatch<React.SetStateAction<SliderValue>>;
  value: SliderValue;
}> = ({ setSliderValue, value }) => {
  // Handler for touch move
  const handleTouchEvent = async (e: React.TouchEvent) => {
    const newValue = calculateNewSliderValue(e.touches[0].clientX, value);
    await handleJumpToSlice(newValue, setSliderValue);
  };
  const isScreenWiderThan950 = window.screen.availWidth > 950;
  return (
    <div className='slider-container'>
      <div
        className='arrow-icon'
        onClick={async () => {
          await IncrementSliceByDelta(-1, setSliderValue);
        }}
      >
        <ArrowIcon />
      </div>
      <div className='input-container' onTouchStart={handleTouchEvent} onTouchMove={handleTouchEvent}>
        <input
          type='range'
          id='volume-slider'
          value={value.currentSlice}
          min={0}
          max={value.maxSlice - 1}
          onChange={async (e) => {
            /* since the touch event is handled above this check prevents double calling
            when on mobile */
            if (isScreenWiderThan950) {
              const newValue = Number(e.target.value);
              await handleJumpToSlice(newValue, setSliderValue);
            }
          }}
        />
      </div>
      <div
        className='rotate-180 arrow-icon'
        onClick={async () => {
          await IncrementSliceByDelta(1, setSliderValue);
        }}
      >
        <ArrowIcon />
      </div>
    </div>
  );
};

export default UISlider;
