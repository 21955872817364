import { useCallback, useEffect, useState } from 'react';

export default function useAdvancedMenu(): [boolean, () => void] {
  const isMenuOpen = new URLSearchParams(window.location.search).get('advanced_menu') === 'true';

  const [isAdvancedMenuOpen, setIsAdvancedMenuOpen] = useState<boolean>(isMenuOpen);

  const handleUrlChange = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const advancedMenu = params.get('advanced_menu');
    setIsAdvancedMenuOpen(advancedMenu === 'true');
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [handleUrlChange]);

  const toggleAdvancedMenu = useCallback(() => {
    const url = new URL(window.location.href);
    const newValue = !isAdvancedMenuOpen;
    url.searchParams.set('advanced_menu', newValue.toString());
    window.history.replaceState({}, '', url.toString());
    window.dispatchEvent(new Event('popstate'));
    setIsAdvancedMenuOpen(newValue);
  }, [isAdvancedMenuOpen]);

  return [isAdvancedMenuOpen, toggleAdvancedMenu];
}
