import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import WarningIcon from 'components/ui/icons/WarningIcon';
import './Dropdown.css';

export type DropdownItem = {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
};

type DropdownProps = {
  items: DropdownItem[];
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

const DisabledItem = ({ label }) => {
  const theme = useTheme();
  return (
    <div className="disabled-item">
      <span style={{ color: theme.palette.text.secondary }}>{label}</span>
      <div className="dropdown-item-warning">
        <WarningIcon />
        <span style={{ color: theme.palette.warning.main }}>Image Unavailable</span>
      </div>
    </div>
  );
};

const UIDropdown = ({ items, activeIndex, setActiveIndex }: DropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isEmptyDropdown = items.length < 1;

  const handleItemClick = (index: number, item: DropdownItem) => {
    setIsDropdownOpen(false);
    if (index !== activeIndex) {
      setActiveIndex(index);
      item?.onClick?.();
    }
  };

  return (
    <div className='dropdown'>
      <div className='dropdown-header' onClick={() => {
        if (!isEmptyDropdown) setIsDropdownOpen(!isDropdownOpen);
      }}>
        <span>{items[activeIndex]?.label}</span>
        {isDropdownOpen ? (
          <KeyboardArrowUp className={`dropdown-arrow ${isEmptyDropdown ? 'transparent-arrow' : ''}`}/>
        ) : (
          <KeyboardArrowDown className={`dropdown-arrow ${isEmptyDropdown ? 'transparent-arrow' : ''}`}/>
        )}
      </div>

      {isDropdownOpen && (
        <div className='dropdown-list'>
          {items.map((item, index) => (
            <div
              key={index}
              className={`dropdown-item ${index === activeIndex ? 'selected' : ''} ${
                item.disabled ? 'disabled' : ''
              }`}
              onClick={!item.disabled ? () => handleItemClick(index, item) : undefined}
              aria-disabled={item.disabled}
            >
              {item.disabled ? (
                <DisabledItem label={item.label} />
              ) : (
                <span>{item.label}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UIDropdown;
