import React from 'react';
import { getViewport } from 'utils/utilities';
import * as cornerstoneTools from '@cornerstonejs/tools';
import { Enums } from '@cornerstonejs/tools';
import { SliderValue, WindowingValue } from 'types';
import { TOOL_GROUP_ID } from '../../constants';

const { ToolGroupManager, PanTool, ZoomTool } = cornerstoneTools;

type BindingConfig = {
  mouseButton?: number;
  numTouchPoints?: number;
};

type ToolConfig = {
  toolName: string;
  bindings: BindingConfig[];
};

export const activateTools = (toolConfigs: ToolConfig[]) => {
  const toolGroup = ToolGroupManager.getToolGroup(TOOL_GROUP_ID);

  if (toolGroup) {
    // Deactivate all tools
    Object.keys(toolGroup.toolOptions).forEach((tool) => {
      toolGroup.setToolDisabled(tool);
    });

    // Activate the specified tools with their respective bindings
    toolConfigs.forEach(({ toolName, bindings }) => {
      toolGroup.setToolActive(toolName, { bindings });
    });
  }
};

// Function to calculate windowing values
export const calculateWindowingValues = (): WindowingValue => {
  const viewport = getViewport();
  const transferFunction = viewport?.getActors()[0]?.actor.getProperty().getRGBTransferFunction(0);
  const [currentMin, currentMax] = transferFunction.getMappingRange();
  return {
    windowWidth: currentMax - currentMin,
    windowCenter: (currentMax + currentMin) / 2,
  };
};

// Function to set windowing values using the calculated values
export const getAndSetWindowingValues = (
  setWindowingValues: React.Dispatch<React.SetStateAction<WindowingValue>>,
) => {
  const windowingValues = calculateWindowingValues();
  setWindowingValues(windowingValues);
};

export const activateWindowLevelTool = (
  setWindowingValues: React.Dispatch<React.SetStateAction<WindowingValue>>,
) => {
  const windowingElement = document.querySelector('#windowing');
  if (windowingElement) {
    activateTools([
      {
        toolName: cornerstoneTools.WindowLevelTool.toolName,
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Primary,
          },
          {
            numTouchPoints: 1,
          },
        ],
      },
      {
        toolName: cornerstoneTools.ZoomTool.toolName,
        bindings: [
          {
            numTouchPoints: 2,
          },
        ],
      },
    ]);
    // Remove active class from all buttons to avoid confusion with presets
    const menuItems = windowingElement.querySelectorAll('.windowing-menu-item');
    menuItems.forEach((item) => item.classList.remove('active'));

    getAndSetWindowingValues(setWindowingValues);
  }
};

export const activateDefaultTooling = () => {
  activateTools([
    {
      toolName: PanTool.toolName,
      bindings: [
        {
          mouseButton: Enums.MouseBindings.Secondary,
        },
      ],
    },
    {
      toolName: ZoomTool.toolName,
      bindings: [
        { numTouchPoints: 2 },
        {
          mouseButton: Enums.MouseBindings.Primary,
        },
      ],
    },
  ]);
};

export const calculateSliceValues = (): SliderValue | null => {
  try {
    const viewport = getViewport();
    const maxSlice = viewport?.getNumberOfSlices();
    const currentSlice = viewport.getSliceIndex();
    return {
      currentSlice,
      maxSlice,
    };
  } catch (error) {
    return null;
  }
};

export const getOrientation = () => {
  const viewport = getViewport();
  return viewport?.defaultOptions?.orientation;
};
